import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { Img } from 'ha/ui/Img';
import {
  Button,
  Container,
  Grid,
  Link,
  Typography,
  units,
} from '@hbf/dsl/legacy';
import { City } from '@ha/api/v2/types';

import Rocket404 from 'ha/img-src/static/rocket-404.svg';

import { useSelector } from 'react-redux';
import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';
import { CitiesList } from './CitiesList';
import { PAGE_NOT_EXISTS } from './constants';

const useStyles = makeStyles()({
  container: {
    paddingTop: units.rem(3),
    minHeight: units.vh(80),
  },
  image: {
    height: '30vh',
  },
  heading: {
    margin: '2rem 0',
  },
  startSearchCta: {
    marginBottom: units.rem(1),
  },
  featureCitiesTitle: {
    marginBottom: units.rem(1),
  },
});

interface Props {
  featuredCities?: City[];
  children?: React.ReactNode;
}

export const NotFound: React.FC<Props> = ({ featuredCities, children }) => {
  const isAdvertiser = useSelector(getIsAdvertiser);
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container justifyContent="center">
        <div>
          <Img
            alt={T('Page not found')}
            src={Rocket404}
            className={classes.image}
          />
        </div>

        {children || (
          <Grid
            container
            spacing={2}
            direction="column"
            className={classes.heading}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography.H3 data-test-locator="404 Error" align="center">
                {T(PAGE_NOT_EXISTS)}
              </Typography.H3>
            </Grid>
            <Grid item>
              <Track name="404 Start your search" type="link">
                <Button
                  color="primary"
                  variant="contained"
                  href={urlResolver.getHomeUrl({
                    explicitNoRedirect: isAdvertiser,
                  })}
                  className={classes.startSearchCta}
                >
                  {T('Start your search')}
                </Button>
              </Track>
            </Grid>

            <Grid item>
              <Typography.Paragraph align="center">
                {T('Or, visit these useful links instead')}:
              </Typography.Paragraph>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Track name="How to rent a place 404" type="link">
                    <Link
                      to={urlResolver.getRenting()}
                      kind="mint-underline"
                      rel="nofollow"
                    >
                      {T('How to rent a place')}
                    </Link>
                  </Track>
                </Grid>
                <Grid item>
                  <Track name="Clicked FAQ in 404" type="link">
                    <Link
                      to={urlResolver.getAnswersUrl()}
                      kind="mint-underline"
                      rel="nofollow"
                    >
                      {T('Frequently Asked Questions')}
                    </Link>
                  </Track>
                </Grid>

                <Grid item>
                  <Track name="Clicked Become an advertiser in 404" type="link">
                    <Link
                      to={urlResolver.getBecomeAnAdvertiserUrl()}
                      kind="mint-underline"
                      rel="nofollow"
                    >
                      {T('Become an advertiser')}
                    </Link>
                  </Track>
                </Grid>
              </Grid>
            </Grid>

            <br />

            {featuredCities && featuredCities.length > 0 && (
              <Grid container direction="column">
                <Grid item>
                  <Typography.Paragraph
                    className={classes.featureCitiesTitle}
                    align="center"
                  >
                    {T('Check our featured cities:')}
                  </Typography.Paragraph>
                </Grid>
                <Grid item>
                  <CitiesList cities={featuredCities} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
