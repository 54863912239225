import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Grid, Link, Text } from '@hbf/dsl/legacy';
import { City } from '@ha/api/v2/types';

interface Props {
  cities: City[];
}

const useStyles = makeStyles()({
  linkWrapper: {
    textAlign: 'center',
  },
});

const CitiesList: React.FC<Props> = ({ cities }) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={1}>
      {cities.map(city => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={city.searchUrl}
          className={classes.linkWrapper}
          data-test-locator="CitiesList__item"
        >
          <Link
            to={city.searchUrl}
            kind="mint"
            title={`${city.cityName}, ${city.countryName}`}
          >
            <Text display="inline" fw="bold">
              {city.cityName}
            </Text>
            <span>, {city.countryName}</span>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export { CitiesList };
