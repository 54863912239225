import React from 'react';
import { useSelector } from 'react-redux';
import { NOT_FOUND } from 'ha/constants/pageNames';
import { Page } from 'ha/modules/Page';
import { getFeaturedCities } from 'ha/modules/FeaturedCities/selectors';
import { NotFound } from 'ha/components/NotFound';

const NotFoundPage: React.FC = () => {
  const featuredCities = useSelector(getFeaturedCities);

  return (
    <Page name={NOT_FOUND} statusCode={404}>
      <NotFound featuredCities={featuredCities} />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default NotFoundPage;
